@charset "UTF-8";
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.vertical.selfScroll::-webkit-scrollbar,
.smooth-scroll:not(.default-scroll)::-webkit-scrollbar,
.cdk-virtual-scroll-orientation-vertical::-webkit-scrollbar {
  overflow-y: scroll;
  width: 6px;
}

.vertical.selfScroll::-webkit-scrollbar-thumb,
.smooth-scroll:not(.default-scroll)::-webkit-scrollbar-thumb,
.cdk-virtual-scroll-orientation-vertical::-webkit-scrollbar-thumb {
  background: #dadee2;
}

.vertical.selfScroll::-webkit-scrollbar-track,
.smooth-scroll:not(.default-scroll)::-webkit-scrollbar-track,
.cdk-virtual-scroll-orientation-vertical::-webkit-scrollbar-track {
  background: #f7f8fa;
  box-shadow: inset 0 1px 0 0 #f5f8fa, inset 0 -1px 0 0 #f5f8fa;
}